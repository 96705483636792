import { Link } from 'react-router-dom';
import styled from 'styled-components';

interface UserAvatarProps {
  avatarSrc: string;
}
export const MainContent = styled.main`
  display: flex;
  align-items: stretch;
  width: 100%;
  font-size: 1rem;

  @media (max-width: 1224px) {
    flex-direction: column; /* Altera a direção do flex para coluna no mobile */
  }
`;

export const Dashboard = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 2.5rem 3.5rem;

  @media (max-width: 1224px) {
    padding: 2rem 3rem;
  }

  @media (max-width: 600px) {
    padding: 1rem 2rem;
  }
`;

export const WelcomeSection = styled.header`
  display: flex;
  align-items: center;
  font-size: 1rem;

  .primary-button {
    width: 13rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    color: #fff;

    border-radius: 0.55rem;
    min-width: 10.57rem;
    min-height: 2.82rem;
    box-shadow: 0px 3px 6px rgba(255, 159, 0, 0.3);
  }

  > a:first-of-type {
    cursor: pointer;
    width: 6.5rem;
    height: 6.5rem;
    padding: 0.33rem;
    border-radius: 50%;
    border: 3px solid #f39519;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    transition: box-shadow 0.3s;

    &:hover {
      box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.16);
    }
  }

  span {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1;
    padding-left: 2rem;

    > a {
      width: fit-content;
      flex: 0;
      letter-spacing: 0;
      font-family: 'RedHatDisplay-Regular';
      font-size: 2.24rem;
      line-height: 2.48rem;
      margin-bottom: 0.5rem;
      color: #1e88e5;
      text-decoration: none;
      opacity: 0.8;
      transition: opacity 0.3s;

      &:hover {
        opacity: 1;
      }
    }

    > a b {
      font-family: 'RedHatDisplay-Bold';
      font-weight: normal;
    }

    p {
      font-size: 1rem;
      line-height: 1.2rem;
      font-family: 'RedHatDisplay-Regular';
      color: #3a3a3a;
      padding-left: 2px;
    }
  }

  button > a {
    font-size: 1rem;
    display: flex;
    align-items: center;
    padding: 0.9rem 1.8rem;
    border-radius: 0.5rem;
    box-shadow: 0 3px 15px rgba(243, 149, 25, 0.3);
    background: #f39519;
    background-size: 200% auto;
    transition: 0.3s;

    svg {
      margin-right: 8px;
    }

    span {
      font-family: 'RedHatDisplay-Bold';
      color: #fff;
    }

    &:hover {
      background-position: right center;
    }
  }

  @media (max-width: 1224px) {
    order: 1; /* Define a ordem do WelcomeSection como 1 (primeiro) */
    > a:first-of-type {
      margin-bottom: 1rem; /* Adiciona espaço abaixo do avatar */
    }

    span {
      padding-left: 1rem; /* Remove o padding à esquerda */
    }

    button {
      margin-top: 1rem; /* Adiciona espaço acima do botão */
    }
  }

  @media (max-width: 600px) {
    > a:first-of-type {
      display: none;
    }
  }
`;

export const UserAvatar = styled.div<UserAvatarProps>`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: url('${props => props.avatarSrc}') no-repeat center;
  background-size: cover;
`;

export const ActivitiesSidebar = styled.aside`
  width: var(--videobar-width);
  min-height: 100vh;
  height: auto;
  background: #1e88e5;
  padding: 2.8rem;
  display: flex;
  flex-direction: column;
  alig-items: center;

  > svg {
    z-index: 1001;
  }

  > h1 {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  > header {
    margin-top: 2.5rem;
    display: flex;
    flex-direction: column;
    padding-bottom: 1.12rem;
    margin-bottom: 1.12rem;
    border-bottom: 0.5px solid rgba(255, 255, 255, 0.1);

    > a {
      cursor: pointer;
      display: flex;
      align-items: center;
      margin-bottom: 1.22rem;
      transition: all 0.3s;
      text-decoration: none;

      > h3 {
        font-family: 'RedHatDisplay-ExtraBold';
        font-size: 1.77rem;
        color: #fff;
        opacity: 0.7;
        transition: opacity 0.3s;
      }

      h3 + svg {
        margin-left: auto;
      }

      &:hover {
        h3 {
          opacity: 1;
        }
      }
    }

    h4 {
      font-family: 'RedHatDisplay-Regular';
      font-size: 1rem;
      color: #fff;
    }
  }

  @media (max-width: 1224px) {
    order: 2; /* Define a ordem do ActivitiesSidebar como 2 (segundo) */
    width: 100%; /* Ocupa toda a largura */
    min-height: auto;
    padding: 1.5rem; /* Reduz o padding */

    > header {
      margin-top: 1rem; /* Reduz a margem superior */
      padding-bottom: 0.5rem; /* Reduz o padding inferior */
      margin-bottom: 0.5rem; /* Reduz a margem inferior */

      > a {
        margin-bottom: 1.83rem; /* Reduz a margem inferior */
        justify-content: center;
        > h3 {
          margin-right: 1.63rem;
        }

        h3 + svg {
          margin-left: 0;
        }
      }

      h4 {
        font-size: 1.5rem; /* Reduz o tamanho da fonte */
        font-family: 'RedHatDisplay-Medium';
      }
    }
  }
`;

export const LogoFuzzyMakers = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  svg:last-child {
    margin-left: 0.2rem;
  }

  @media (max-width: 1224px) {
    display: none;
  }
`;

export const ActivitiesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > h2 {
    text-align: center;
    margin-top: 1.5rem;
    margin-bottom: 3rem;
    font-family: 'RedHatDisplay-Bold';
    color: #fff;
    font-size: 1.56rem;

    > strong {
      color: #f39519;
    }
  }

  @media (max-width: 1224px) {
    > h2 {
      font-size: 1.44rem; /* Reduz o tamanho da fonte */
      margin-top: 1rem; /* Reduz a margem superior */
      margin-bottom: 2rem; /* Reduz a margem inferior */
    }
  }
`;

export const IconArea = styled.div`
  width: 6.12rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  alig-items: center;
  justify-content: center;
  position: relative;

  .startPoint {
    background-color: #f39519;
    width: 0.61rem;
    height: 0.61rem;
    border-radius: 50%;
    position: absolute;
    top: -1rem;
    left: 40%;
  }

  .endPoint {
    background-color: #f39519;
    margin-left: 2rem;
    /* position: fixed; */
    width: 0.61rem;
    height: 0.61rem;
    border-radius: 50%;
    margin-bottom: 0;
    margin-top: 0.56rem;
  }
`;

export const StartPoint = styled.div``;

export const EndPoint = styled.div``;

export const IconActivity = styled.div`
  display: flex;
  position: relative;
  > svg {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
`;

export const Circle = styled.div`
  background-color: #4caf50;
  border: 2px solid #ededed;
  width: 3.9rem;
  height: 3.9rem;
  border-radius: 50%;
`;

export const Cursor = styled.div``;

export const TextArea = styled.div`
  margin-left: 0.5rem;

  > h1 {
    font-family: 'RedHatDisplay-ExtraBold';
    font-size: 1rem;
    color: #fff;
  }

  > p {
    font-family: 'RedHatDisplay-Medium';
    font-size: 0.67.rem;
    color: #fff;
  }
  > div {
    margin-top: 0.27rem;
    width: 2.94rem;
    height: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: center;
    border-radius: 2.78rem;
    background-color: #4caf50;

    font-family: 'RedHatDisplay-Regular';
    font-size: 0.5rem;
    color: #fff;
    text-align: center;
  }
`;

export const Line = styled.div`
  display: flex;
  position: absolute;
  top: -0.25rem;
  left: -0.1rem;
  flex-direction: column;
  align-items: center;

  > svg {
    position: relative;
  }
`;

export const ShimmerEffect = styled.div`
  background-image: linear-gradient(
    -90deg,
    #1e88e5 0%,
    #4ba0ea 50%,
    #1e88e5 100%
  );
  background-size: 400% 400%;
  animation: shimmer 1.2s ease-in-out infinite;
  @keyframes shimmer {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }

  &.white {
    background-image: linear-gradient(
      -90deg,
      #1e88e5 0%,
      #4ba0ea 50%,
      #1e88e5 100%
    );
  }
`;

export const ShimmerWrapper = styled.div`
  width: 100%;
  height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;

  .shimmer-medium-title {
    width: 80%;
    height: 10%;
    border-radius: 1rem;
    margin-top: 1.5rem;
    margin-bottom: 3rem;
  }

  .shimmer-activity {
    width: 100%;
    height: 6rem;
    border-radius: 1rem;
    margin-bottom: 3rem;
  }
`;
