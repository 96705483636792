import styled from 'styled-components';

export const Container = styled.div`
  max-width: 100vw;
  display: flex;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  max-width: calc(100vw - var(--sidebar-width));
  width: calc(100vw - var(--sidebar-width));
  margin-left: var(--sidebar-width);

  @media (max-width: 1224px) {
    max-width: 100%;
    width: 100%;
    margin-left: 0;
  }
`;
